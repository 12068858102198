import React, { useCallback, useEffect } from "react";
import "./App.css";
import { FormControl, Box, Button, Container, Dialog, MenuItem, Select, styled, Typography } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TuneIcon from "@mui/icons-material/Tune";
import SaveIcon from "@mui/icons-material/Save";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PauseIcon from "@mui/icons-material/Pause";
import { Timer } from "./Components/Timer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PresetDialog } from "./Components/Preset";
import { parsePreset } from "./Utils";
import IconButton from "@mui/material/IconButton";
import { MoveChart } from "./Components/MoveChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LeaderboardDialog from "./Components/Leaderboard";
import Leaderboard from "./services/api";
import { CircularProgress } from "@mui/material";
import ImageService from './services/image';
import Camera from "./Components/camera";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#E7EBEF",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  minHeight: "40vh",
  color: theme.palette.text.secondary,
  justifyContent: "center",
  position: "relative",
  flexBasis: 0,
  flexGrow: 1,
  flexDirection: "column-reverse",
}));

const ControlBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#333436",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.secondary,
  justifyContent: "space-around",
  flexBasis: 0,
  flexGrow: 0.1,
}));

const PresetBox = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.secondary,
  position: "absolute",
  bottom: "1rem",
}));

const MoveBox = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.secondary,
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
  fontWeight: "bold",
}));

const activeColor = "#77ff38";
const defaultColor = "#E7EBEF";

const _leaderboard = new Leaderboard();
interface ClockState {
  player: string;
  sound: boolean;
  started: boolean;
  startTime?: Date;
  moveCount: number;
  preset: string;
  whiteRemaining: number;
  blackRemaining: number;
  increment: number;
  paused: boolean;
  blackMoves: number[];
  whiteMoves: number[];
  showChart: boolean;
  whitePlayer?: string;
  blackPlayer?: string;
  boardImage?: string;
  winner?: string;
}

const defaultSetup: ClockState = {
  startTime: undefined,
  moveCount: 0,
  player: "",
  sound: true,
  paused: false,
  started: false,
  blackMoves: [],
  whiteMoves: [],
  showChart: true,
  whitePlayer: "",
  blackPlayer: "",
  winner: "",

  ...parsePreset("5 min"),
};

const audio = new Audio(document.location.href + "/click.mp3");

function App() {

  const [open, setOpen] = React.useState(false);

  const [clockState, setClockState] = React.useState<ClockState>({
    ...defaultSetup,
  });
  const [openleaderboard, setOpenLeaderboard] = React.useState(false);
  const [leadersLoaded, setLeadersLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [leaders, setLeaders] = React.useState<any[]>([]);
  const [savingGame, setSavingGame] = React.useState(false);

  const resetClock = () => {
    setClockState({
      ...defaultSetup,
    });
  };
  const startGame = () => {
    if (clockState.started && clockState.paused) {
      toast.info("Game Resumed");
      setClockState({
        ...clockState,
        paused: false,
        startTime: new Date(),
      });
      return;
    }

    toast.success("Game started");
    setClockState({
      ...clockState,
      player: clockState.paused ? clockState.player : "white",
      started: true,
      paused: false,
      moveCount: 0,
      startTime: new Date(),
    });
  };
  const stopClock = () => {
    let blackRemaining = clockState.blackRemaining;
    let whiteRemaining = clockState.whiteRemaining;

    if (clockState.startTime) {
      if (clockState.player === "black") {
        // update white remaining time
        whiteRemaining -=
          new Date().getTime() -
          clockState.startTime.getTime() +
          clockState.increment;
      } else {
        // update black remaining time
        blackRemaining -=
          new Date().getTime() -
          clockState.startTime.getTime() +
          clockState.increment;
      }
    }

    setClockState({
      ...clockState,
      blackRemaining,
      whiteRemaining,
      paused: true,
    });
  };
  const setSoundOnOff = (isOn: boolean) => {
    setClockState({
      ...clockState,
      sound: isOn,
    });
  };
  const updateState = (updatedFields: Partial<ClockState>) => {
    setClockState({ ...clockState, ...updatedFields });
  };
  const toggleChart = () => {
    updateState({ showChart: !clockState.showChart });
  };
  const finishedMove = (player: "white" | "black") => {
    if (clockState.sound) {
      audio.play();
    }

    if (clockState.blackRemaining <= 0 || clockState.whiteRemaining <= 0) {
      return;
    }
    if (
      !clockState.started ||
      (player !== clockState.player && clockState.moveCount > 0) ||
      (player === "black" && clockState.moveCount === 0)
    ) {
      console.log("Not your turn, skip");
      return;
    }
    let blackRemaining = clockState.blackRemaining;
    let whiteRemaining = clockState.whiteRemaining;
    let blackMoves = clockState.blackMoves;
    let whiteMoves = clockState.whiteMoves;

    if (clockState.startTime) {
      const moveTime = new Date().getTime() - clockState.startTime?.getTime();

      if (player === "white") {
        whiteRemaining = whiteRemaining - moveTime + clockState.increment;
        whiteMoves = [...whiteMoves, moveTime];
      } else {
        blackRemaining = blackRemaining - moveTime + clockState.increment;
        blackMoves = [...blackMoves, moveTime];
      }
      
    }
    setClockState({
      ...clockState,
      player: player === "white" ? "black" : "white",
      moveCount:
        player === "white" ? clockState.moveCount + 1 : clockState.moveCount,
      startTime: new Date(),
      blackRemaining,
      whiteRemaining,
      blackMoves,
      whiteMoves,
    });
  };
  const handleClosePreset = (preset: string, players?: string[]) => {
    // reset game
    const newSetup = parsePreset(preset);
    // console.log(players);
    setClockState({
      ...clockState,
      whitePlayer: players?.[1],
      blackPlayer: players?.[0],
      ...newSetup,
      paused: false,
      started: false,
      player: "",
      moveCount: 0,
    });
    setOpen(false);
  };
  const getColor = useCallback(
    (player: string) => {
      if (player === clockState.player) {
        const remaining =
          player === "white"
            ? clockState.whiteRemaining
            : clockState.blackRemaining;
        if (remaining < 30000) return "#fd0000";

        return activeColor;
      }
      return defaultColor;
    },
    [clockState.player, clockState.blackRemaining, clockState.whiteRemaining]
  );
  const handleShowLeaderBoard = async () => {
    // console.log("Show Leaderboard");
    const leaders = await _leaderboard.getLeaderboard();
    // console.log(leaders);
    setLeaders(leaders);
    setLeadersLoaded(true);
    setOpenLeaderboard(true);
  }
  const handleTakePhoto = (imgData: string) => {
    handlePostGameResults(imgData);   
  }
  const handlePostGameResults = async (img: string) => {
    const data = {
      player: clockState.player,
      sound: clockState.sound,
      started: clockState.started,
      startTime: clockState.startTime,
      moveCount: clockState.moveCount,
      preset: clockState.preset,
      whiteRemaining: clockState.whiteRemaining,
      blackRemaining: clockState.blackRemaining,
      increment: clockState.increment,
      paused: clockState.paused,
      blackMoves: clockState.blackMoves,
      whiteMoves: clockState.whiteMoves,
      showChart: clockState.showChart,
      whitePlayer: clockState.whitePlayer,
      blackPlayer: clockState.blackPlayer,
      boardImage: img,
      winner: clockState.winner
    };
    // console.log(data);
    if(clockState.whitePlayer !== '' &&  clockState.blackPlayer !== ''){
      await _leaderboard.postLeaderboard(data?.winner as string, 1);
      await _leaderboard.postHistory(data);
    }
    
    setSavingGame(false);
  }
  const handleSaveGame = async () => {
    // console.log(clockState)
    setClockState({...clockState, paused: true});
    setSavingGame(true);
  }


  return (
    <Container maxWidth="sm" disableGutters className="prevent-select">
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <StyledBox
          sx={{
            backgroundColor: getColor("black"),
            transform: "rotate(180deg)",
            cursor: clockState.player === "black" ? "pointer" : "default",
          }}
          onClick={() => finishedMove("black")}
        >
          {clockState.showChart && <MoveChart data={clockState.blackMoves} />}
          <PresetBox className="prevent-select">{clockState.preset}</PresetBox>
          <Timer
            resetToken={clockState.startTime}
            time={clockState.blackRemaining}
            ticking={
              clockState.started &&
              !clockState.paused &&
              clockState.player === "black"
            }
          ></Timer>
          <MoveBox>{clockState.whitePlayer} Moves: {Math.max(0, clockState.moveCount - 1)}</MoveBox>
        </StyledBox>


        <ControlBox>
          {!clockState.started || clockState.paused ? <IconButton>
            <EmojiEventsIcon color="info" onClick={handleShowLeaderBoard}/>
            </IconButton> : null}
          
          <IconButton>
            <RestartAltIcon color="error" onClick={resetClock} />
          </IconButton>
          {
            clockState.started ? <IconButton>
            <SaveIcon color="primary" onClick={handleSaveGame} />
          </IconButton> : null
          }
          {clockState.started && !clockState.paused && (
            <IconButton>
              <PauseIcon color="warning" onClick={stopClock} />
            </IconButton>
          )}
          {(!clockState.started || clockState.paused) && (
            <IconButton>
              <PlayArrowIcon color="primary" onClick={startGame} />
            </IconButton>
          )}
          <IconButton>
            <TuneIcon color="primary" onClick={() => setOpen(true)} />
          </IconButton>
          {clockState.sound && (
            <IconButton>
              <VolumeUpIcon
                color="primary"
                onClick={() => setSoundOnOff(false)}
              />
            </IconButton>
          )}
          {!clockState.sound && (
            <IconButton>
              <VolumeOffIcon
                color="error"
                onClick={() => setSoundOnOff(true)}
              />
            </IconButton>
          )}
          {(!clockState.started || clockState.paused)? <IconButton>
            <BarChartIcon
              color={clockState.showChart ? "error" : "success"}
              onClick={toggleChart}
            />
          </IconButton> : null }
          
         
        </ControlBox>
        
        
        <StyledBox
          sx={{
            backgroundColor: getColor("white"),
            cursor: clockState.player === "white" ? "pointer" : "default",
          }}
          onClick={() => finishedMove("white")}
        >
          <PresetBox>{clockState.preset}</PresetBox>
          {clockState.showChart && <MoveChart data={clockState.whiteMoves} />}
          <Timer
            resetToken={clockState.startTime}
            time={clockState.whiteRemaining}
            ticking={
              clockState.started &&
              !clockState.paused &&
              clockState.player === "white"
            }
          ></Timer>
          <MoveBox>{clockState.blackPlayer} Moves: {Math.max(clockState.moveCount, 0)}</MoveBox>
        </StyledBox>
      </Box>

      <ToastContainer theme="colored" autoClose={1000} limit={1} />

      <PresetDialog
        open={open}
        onClose={handleClosePreset}
        selectedValue={clockState.preset}
      />

      {leadersLoaded && leaders.length > 0 ? (<LeaderboardDialog leaders={JSON.parse(leaders as any)} open={openleaderboard} onClose={()=>{
        setOpenLeaderboard(false);
      }}/>) : null}

      <Dialog open={savingGame} style={{textAlign: 'center'}} onClose={()=>{setSavingGame(false)}}>
      <div style={{}}>
        <h4>Who won?</h4>
      <Button variant="contained"  onClick={()=>{ setClockState({...clockState, winner: clockState.whitePlayer})}}>White - { clockState.whitePlayer}</Button>
      <br />
      <Button  variant="contained" onClick={()=>{ setClockState({...clockState, winner: clockState.blackPlayer})}}>Black - { clockState.blackPlayer}</Button>
            <br />
            <Camera onCapture={handleTakePhoto} />
            <br />
            </div>
        </Dialog>

    </Container>
  );
}

export default App;
