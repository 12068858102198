import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Input,
  ListItemIcon
} from "@mui/material";

const presets = [
  "1 min",
  "3 min",
  "5 min",
  "3 min | 2 sec",
  "5 min | 3 sec",
  "10 min | 5 sec",
  "15 min | 15 sec",
  "30 min | 30 sec",
];

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string, names?: string[]) => void;
}

export function PresetDialog(props: SimpleDialogProps) {

  const { onClose, selectedValue, open } = props;
  const [wPlayerName, setWPlayerName] = React.useState<string | null>(null);
  const [bPlayerName, setBPlayerName] = React.useState<string | null>(null);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handlePlayerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "whiteName") {
      setWPlayerName(e.target.value);
    } else {
      setBPlayerName(e.target.value);
    }
  };

  const handleListItemClick = (value: string) => {
    if (wPlayerName && bPlayerName) {
      console.log([wPlayerName, bPlayerName]);
      onClose(value,[wPlayerName, bPlayerName] );
    }
  };


  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>Game Settings</DialogTitle>
      <div style={{ margin: "10px" }}>
        <h4>Player Names</h4>

        <Input
          type="text"
          onChange={handlePlayerNameChange}
          placeholder="White Player Name"
          name="whiteName"
          style={{ marginBottom: 15 }}
        />
        <br />
        <br />
        <Input
          type="text"
          onChange={handlePlayerNameChange}
          placeholder="Black Player Name"
          name="blackName"
          style={{ marginBottom: 15 }}
        />
       <br />

        <h4>Game Length</h4>
        <List sx={{ pt: 0 }}>
          {presets.map((preset) => (
            <ListItem disableGutters key={preset} sx={{ padding: 0 }}>
              <ListItemButton
                onClick={() => handleListItemClick(preset)}
                key={preset}
              >
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary={preset} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

      </div>
    </Dialog>
  );
}
