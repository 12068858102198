

interface LeaderboardData {
    name: string;
    wins: number;
}

interface ClockState {
    player: string;
    sound: boolean;
    started: boolean;
    startTime?: Date;
    moveCount: number;
    preset: string;
    whiteRemaining: number;
    blackRemaining: number;
    increment: number;
    paused: boolean;
    blackMoves: number[];
    whiteMoves: number[];
    showChart: boolean;
    whitePlayer?: string;
    blackPlayer?: string;
    boardImage?: string;
  }
export default class Leaderboard {

    _url : string = 'https://chess-leaderboard.codeplant.workers.dev';
    constructor() {
    }

    async getLeaderboard(): Promise<LeaderboardData[]> {
        try {
            const response = await fetch(`${this._url}?type=leaderboard`);
            const data = await response.json();
            return data.leaders || [];
        } catch (error) {
            console.error(error);
            return [];
        }
    }
    async getHistory(): Promise<ClockState[]> {
        try {
            const response = await fetch(`${this._url}?type=history`);
            const data = await response.json();
            return JSON.parse(data.history) || [];
        } catch (error) {
            console.error(error);
            return [];
        }
    }
    async postLeaderboard(name: string, score: number) {
        try {
            const response = await fetch(`${this._url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type: 'leaderboard', entry: { name, score } }),
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }
    async postHistory(_data: ClockState) {
        try {
            const response = await fetch(`${this._url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin":"*",
                    "Access-Control-Allow-Methods":"GET, POST, PUT, DELETE, OPTIONS",
                },
                body: JSON.stringify({type: 'history', entry:_data}),
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }
}

export function updateLeaderboard(c: LeaderboardData, a: LeaderboardData[]): LeaderboardData[] {
    const index = a.findIndex((e) => e.name === c.name);
    if (index !== -1) {
        a[index].wins++;
    } else {
        a.push({ name: c.name, wins: 1 });
    }
    a.sort((a, b) => b.wins - a.wins);
    return a;
}

