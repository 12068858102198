import Button from '@mui/material/Button';
import React, { useState, useEffect, useRef } from 'react';

interface CameraProps {
  onCapture: (imageData: string) => void;
}

const Camera: React.FC<CameraProps> = ({ onCapture }) => {
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const initializeCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: {facingMode: { exact: "environment" }}  });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        setIsCameraActive(true);
        setIsInitialized(true);
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const scale = 0.5; // Reduce image size to 50% of the original resolution
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;
      canvas.width = videoWidth;
      canvas.height = videoHeight;
  
      context?.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
      const quality = 0.5; // Adjust quality from 0 (worst) to 1 (best), default is 0.92
      const imageData = canvas.toDataURL('image/png', quality); // Using JPEG for better compression
  
      onCapture(imageData);
      videoRef.current.srcObject = null;
      videoRef.current.src = imageData;
    }
  };

  useEffect(() => {
    return () => {
      if (videoRef.current?.srcObject) {
        (videoRef.current.srcObject as MediaStream).getTracks().forEach((track) => track.stop());
        setIsCameraActive(false);
      }
    };
  }, []);

  const handleButtonClick = () => {
    if (!isInitialized) {
      initializeCamera();
    } else {
      captureImage();
    }
  };

  return (
    <div style={{width: '100%', textAlign: 'center'}}>
      <video ref={videoRef} style={{ width: 200, height: 200 }} autoPlay playsInline />
      <canvas ref={canvasRef} style={{ display: 'none',width: 200, height: 200 }} />
      <Button variant="contained" onClick={handleButtonClick}>
        {isInitialized ? 'Capture Image' : 'Initialize Camera'}
      </Button>
    </div>
  );
};

export default Camera;
