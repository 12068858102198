import { Dialog, DialogTitle } from '@mui/material'
import GameCard from './history'
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import Leaderboard from "../services/api";


const _service = new Leaderboard();

type Props = {
    open: boolean
    onClose: () => void
    leaders: any[]
}


function LeaderboardDialog({open, onClose, leaders}: Props) {

    const [history, setHistory] = useState<any[]>([]);
    const handleClose = () => {
        onClose();
    };

    const fetchHistory = async () => {

        try {
            const response = await _service.getHistory();
          
            setHistory(response);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchHistory();
    }, [])


  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} 
    style={{padding: 3}}>
        <DialogTitle>Leaderboard</DialogTitle>
        <div style={{ padding: 15, textAlign: 'left'}}>
        { leaders.length > 0 ? (
          <table style={{width:"100%"}}>
          <thead>
              <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Score</th>
              </tr>
          </thead>
          <tbody>
              {leaders.map((leader, index) => (
                  <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{leader.name}</td>
                      <td>{leader.score}</td>
                  </tr>
              ))}
          </tbody>
      </table>) : null}
        </div>
        <hr />
        <DialogTitle>History</DialogTitle>
        <div style={{height: 400, overflowY: 'scroll'}}>
        {history.length > 0 ? <>{history.map((eachOne)=>{
                return <div><GameCard key={eachOne.id} game={eachOne} /></div>
            })}</> : <div style={{textAlign: 'center'}}><CircularProgress /></div>}
        </div>

    </Dialog>
  )
}

export default LeaderboardDialog