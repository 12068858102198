import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface GameProps {
  game: {
    player: string;
    sound: boolean;
    started: boolean;
    startTime: string;
    moveCount: number;
    preset: string;
    whiteRemaining: number;
    blackRemaining: number;
    increment: number;
    paused: boolean;
    blackMoves: number[];
    whiteMoves: number[];
    showChart: boolean;
    whitePlayer: string;
    blackPlayer: string;
    boardImage: string;
    winner: string;
  };
}

//a function to transform a given camelcase string to split up the words and capitalize the first letter of each word
function camelCaseToWords(camelCase: string) {
  return camelCase
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
}

const GameCard: React.FC<GameProps> = ({ game }) => {
  return (
    <Card sx={{ textAlign: 'center', width: '100%' }}>
      
      <CardContent style={{width: '90%'}}>
      <p>{new Date(game.startTime).toDateString()} @ {new Date(game.startTime).toLocaleTimeString()}</p>
        <CardMedia
        sx={{ height: 300}}
        image={game.boardImage}
        title="game image"
      />
        <TableContainer component={Paper}>
          <Table  aria-label="game info table">
            <TableBody>
              {Object.entries(game).map(([key, value], index) => {
                if (key !== 'boardImage' && key !== 'blackMoves' && key !== 'whiteMoves' && key !== 'showChart' && key !== 'sound' && key !== 'paused' && key !== 'started' && key !== 'player'  && key !== 'startTime' && key !== 'increment' && key !== 'preset' && key !== 'whiteRemaining' && key !== 'blackRemaining' ) {
                  return (
                    <TableRow key={key} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                      <TableCell component="th" scope="row">
                        {camelCaseToWords(key)}
                      </TableCell>
                      <TableCell align="center">
                        {typeof value === 'boolean' ? value ? 'Yes' : 'No' : value}
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default GameCard;
